import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import util from 'util';
export var FIREBASE_CONFIG = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
};
export function firebaseApp() {
    if (firebase.apps.length !== 0) {
        return firebase.app();
    }
    if (FIREBASE_CONFIG.apiKey === undefined) {
        throw Error('Firebase API key is not set');
    }
    return firebase.initializeApp(FIREBASE_CONFIG);
}
export function firestore() {
    return firebaseApp().firestore();
}
export function dbfunctions(call) {
    return firebaseApp()
        .functions(process.env.VUE_APP_FIREBASE_REGION)
        .httpsCallable(call);
}
export function function_url(call) {
    return util.format('https://%s-%s.cloudfunctions.net:443/%s', process.env.VUE_APP_FIREBASE_REGION, process.env.VUE_APP_FIREBASE_PROJECT_ID, call);
}
export function bchidentity_url(challenge, cookie) {
    return util.format('%s?op=login&chal=%s&cookie=%s', function_url('identify').replace('https', 'bchidentity'), challenge, cookie);
}
