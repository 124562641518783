var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { VuexModule, Module, Mutation, Action, } from 'vuex-module-decorators';
// NOTE: Vuex Typescript reference implementation
// Ref: https://blog.logrocket.com/how-to-write-a-vue-js-app-completely-in-typescript/
var Alert = /** @class */ (function (_super) {
    __extends(Alert, _super);
    function Alert() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isDisplaying = false;
        _this.content = '';
        return _this;
    }
    Alert.prototype.display = function (content) {
        this.content = content;
        this.isDisplaying = true;
        // scroll to top so that the user sees error
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };
    Alert.prototype.hide = function () {
        this.isDisplaying = false;
        this.content = '';
    };
    Alert.prototype.displayAlert = function (content) {
        this.context.commit('display', content);
    };
    Alert.prototype.hideAlert = function () {
        this.context.commit('hide');
    };
    __decorate([
        Mutation
    ], Alert.prototype, "display", null);
    __decorate([
        Mutation
    ], Alert.prototype, "hide", null);
    __decorate([
        Action
    ], Alert.prototype, "displayAlert", null);
    __decorate([
        Action
    ], Alert.prototype, "hideAlert", null);
    Alert = __decorate([
        Module({ namespaced: true })
    ], Alert);
    return Alert;
}(VuexModule));
export default Alert;
