/* eslint-disable import/extensions */
import Vue from 'vue';
import { CarouselPlugin, BootstrapVue, IconsPlugin, LayoutPlugin, } from 'bootstrap-vue';
import VueSlider from 'vue-slider-component';
import VueQrcodeReader from 'vue-qrcode-reader';
import App from './App.vue';
import router from './router';
import Store from './store';
import blockchain from './services/blockchain';
import 'vue-slider-component/theme/default.css';
import { normalizeAddress } from './validation';
// bootstrap css includes are in style/main.scss
import './style/main.scss';
var VueQrcode = require('@chenfengyuan/vue-qrcode');
var cashaddr = require('cashaddrjs');
Vue.use(VueQrcodeReader);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(LayoutPlugin);
Vue.use(CarouselPlugin);
Vue.config.productionTip = false;
/**
 * Global filters
 */
/**
 * Generates a title from a string.
 * First 40 chars of first line of string.
 */
Vue.filter('makeTitle', function (description) {
    var title = description.split('\n')[0];
    if (title.length <= 40) {
        return title;
    }
    return "".concat(title.substring(0, 40), "...");
});
/**
 * Generates a title from first line of a string.
 */
Vue.filter('makeTitleNoTruncate', function (description) { return description.split('\n')[0]; });
/**
 * Estimates duration between block heights
 */
Vue.filter('estimateTime', function (endHeight, currentHeight) {
    if (endHeight < currentHeight) {
        return 'In the past';
    }
    if (endHeight === currentHeight) {
        return 'Now';
    }
    return blockchain.blockHeightToHuman(currentHeight, endHeight);
});
/**
 * Pretty print a BCH address.
 *
 * This "filter" produces HTML, so needs to be used like
 * <span v-html="ppAddress(address)"></span>
 */
Vue.mixin({
    methods: {
        ppAddress: function (addressIn) {
            if (!addressIn) {
                return '';
            }
            // We don't escape the input, better to sanitize it.
            var address = '';
            try {
                address = normalizeAddress(addressIn);
            }
            catch (e) {
                return "ERROR: ".concat(e);
            }
            var decoded = cashaddr.decode(address);
            var offset = decoded.prefix.length + 1; // + ":"
            var prefix = address.substr(offset, 4);
            var suffix = address.substr(address.length - 2);
            var middle = address.substr(offset + prefix.length, address.length - offset - prefix.length - suffix.length);
            var hash = Buffer.from(decoded.hash).toString('hex');
            var html = "<span\n        title=\"".concat(address.substr(offset), "\"\n        style=\"font-family: 'Lucida Console', Monaco, monospace;\">\n        ").concat(prefix);
            while (middle.length) {
                // color code using the hash (the rotate hash for next chunk).
                var color = hash.substr(0, 4);
                hash = hash.slice(4, hash.length) + color;
                var chunk = middle.slice(0, 4);
                middle = middle.slice(4);
                html += "<span style=\"\n            background-color: #".concat(color, ";\n              letter-spacing: -6px;\n              display: inline-block;\n              width: 8px;\n              height: 14px;\n              overflow: hidden;\n              padding: 0;\n              line-height: 1;\n              color : rgba(0, 0, 0, 0)\n            \">").concat(chunk, "</span>");
            }
            html += "".concat(suffix, "</span>");
            return html;
        },
    },
});
export var viewModel = new Vue({
    render: function (h) { return h(App); },
    router: router,
    store: Store,
}).$mount('#app');
Vue.component(VueQrcode.name, VueQrcode);
Vue.component('VueSlider', VueSlider);
export default viewModel;
