/* eslint-disable import/extensions */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../routes/Dashboard.vue';
import Election from '../routes/Election.vue';
import LandingPage from '../routes/LandingPage.vue';
import Group from '../routes/Group.vue';
import NewElection from '../routes/NewElection.vue';
import 'firebase/auth';
import authService from '../services/auth';
Vue.use(VueRouter);
var routes = [
    {
        component: LandingPage,
        meta: {
            title: 'VotePeer - Landing page',
            requiresAuth: false,
        },
        name: 'LandingPage',
        path: '/',
    },
    {
        component: function () { return import(/* webpackChunkName: "about" */ '../routes/Login.vue'); },
        meta: {
            title: 'VotePeer - Login',
            requiresAuth: false,
        },
        name: 'login',
        path: '/login',
    },
    {
        component: Dashboard,
        meta: {
            title: 'VotePeer - Dashboard',
            requiresAuth: true,
        },
        name: 'dashboard',
        path: '/dashboard',
    },
    {
        component: NewElection,
        meta: {
            title: 'VotePeer - New election',
            requiresAuth: true,
        },
        name: 'NewElection',
        path: '/new_election',
    },
    {
        component: Election,
        meta: {
            title: 'VotePeer - Election',
            requiresAuth: true,
        },
        name: 'election',
        path: '/election/:id',
    },
    {
        component: Group,
        meta: {
            title: 'VoterPeer - Voter Groups',
            requiresAuth: true,
        },
        name: 'groups',
        path: '/groups',
    },
];
var router = new VueRouter({
    routes: routes,
});
router.beforeEach(function (to, from, next) {
    var _a;
    var title = (_a = to.meta) === null || _a === void 0 ? void 0 : _a.title;
    if (title) {
        document.title = title;
    }
    next();
    console.log(to.name);
    if (to.name === 'login') {
        return;
    }
    // Ugly hack: Firebase does not properly trigger auth emits when signing in
    // with custom tokens -- only for anonymous sign in. Force auth check at
    // at every route change.
    authService.forceAuthEmit(null);
});
export default router;
