var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Component, Vue, } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GroupDB } from '../services/group';
import authService from '../services/auth';
var alertStore = namespace('Alert');
var Group = /** @class */ (function (_super) {
    __extends(Group, _super);
    function Group() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ownedGroups = [];
        _this.participatingGroups = [];
        _this.displayQrScanner = false;
        _this.authObserver = null;
        _this.groupDB = new GroupDB();
        _this.newGroupName = '';
        _this.newParticipantAddress = '';
        _this.newParticipantGroup = '';
        return _this;
    }
    Group.prototype.onUserLoaded = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log("Loading group data for ".concat(user));
                        return [4 /*yield*/, Promise.all([
                                this.groupDB.getOwnerOf(user),
                                this.groupDB.getParticipantOf(user)
                            ])];
                    case 1:
                        _a = _b.sent(), this.ownedGroups = _a[0], this.participatingGroups = _a[1];
                        return [2 /*return*/];
                }
            });
        });
    };
    Group.prototype.created = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.authObserver = authService.observeAuth()
                    .subscribe(function (currentUser) { return __awaiter(_this, void 0, void 0, function () {
                    var e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                console.log('Group event: ', currentUser === null || currentUser === void 0 ? void 0 : currentUser.uid, currentUser === null || currentUser === void 0 ? void 0 : currentUser.isAnonymous);
                                if (!currentUser) {
                                    return [2 /*return*/];
                                }
                                if (currentUser.isAnonymous) {
                                    return [2 /*return*/];
                                }
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.onUserLoaded(currentUser.uid)];
                            case 2:
                                _a.sent();
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _a.sent();
                                console.warn(e_1);
                                this.displayAlert("Failed to load groups: ".concat(e_1));
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); }, function (error) {
                    console.warn("subscribe error ".concat(error));
                });
                return [2 /*return*/];
            });
        });
    };
    Group.prototype.onNewGroupPressEnter = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                // close modal
                this.$nextTick(function () {
                    _this.$bvModal.hide('modal-new-group');
                });
                return [2 /*return*/, this.onNewGroupOK()];
            });
        });
    };
    Group.prototype.onNewGroupOK = function () {
        return __awaiter(this, void 0, void 0, function () {
            var owner, result, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.newGroupName) {
                            this.displayAlert('Invalid group name');
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        owner = authService.getUserOrCrash().uid;
                        return [4 /*yield*/, this.groupDB.create(owner, this.newGroupName, [])];
                    case 2:
                        result = _a.sent();
                        console.log("Created group ".concat(result));
                        // Reload group data
                        return [4 /*yield*/, this.onUserLoaded(owner)];
                    case 3:
                        // Reload group data
                        _a.sent();
                        this.newGroupName = '';
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _a.sent();
                        console.warn(e_2);
                        this.displayAlert(e_2);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    Group.prototype.onNewParticipantPressEnter = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                // close modal
                this.$nextTick(function () {
                    _this.$bvModal.hide('modal-add-participant');
                });
                return [2 /*return*/, this.onNewParticipantOK()];
            });
        });
    };
    Group.prototype.addOneParticipant = function (participant) {
        this.newParticipantAddress = participant;
        this.onNewParticipantOK();
    };
    Group.prototype.onNewParticipantOK = function () {
        return __awaiter(this, void 0, void 0, function () {
            var owner, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.newParticipantAddress) {
                            this.displayAlert('Invalid participant address');
                            return [2 /*return*/];
                        }
                        if (!this.newParticipantGroup) {
                            this.displayAlert('Invalid group ID');
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, this.groupDB.addParticipant(this.newParticipantGroup, this.newParticipantAddress)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, authService.getUserOrCrash().uid];
                    case 3:
                        owner = _a.sent();
                        return [4 /*yield*/, this.onUserLoaded(owner)];
                    case 4:
                        _a.sent();
                        this.newParticipantAddress = '';
                        return [3 /*break*/, 6];
                    case 5:
                        e_3 = _a.sent();
                        console.warn(e_3);
                        this.displayAlert(e_3);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    Group.prototype.removeParticipant = function (group, user) {
        return __awaiter(this, void 0, void 0, function () {
            var owner, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.groupDB.removeParticipant(group, user)];
                    case 1:
                        _a.sent();
                        owner = authService.getUserOrCrash().uid;
                        return [4 /*yield*/, this.onUserLoaded(owner)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_4 = _a.sent();
                        console.warn(e_4);
                        this.displayAlert(e_4);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Group.prototype.toggleScanner = function (group) {
        this.displayQrScanner = !this.displayQrScanner;
        this.setParticipantGroup(group.groupId);
    };
    Group.prototype.deleteGroup = function (group) {
        return __awaiter(this, void 0, void 0, function () {
            var owner, e_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.groupDB.delete(group)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, authService.getUserOrCrash().uid];
                    case 2:
                        owner = _a.sent();
                        return [4 /*yield*/, this.onUserLoaded(owner)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_5 = _a.sent();
                        console.warn(e_5);
                        this.displayAlert(e_5);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    Group.prototype.setParticipantGroup = function (group) {
        console.log("Setting newParticipantGroup to ".concat(group));
        this.newParticipantGroup = group;
    };
    __decorate([
        alertStore.Action
    ], Group.prototype, "displayAlert", void 0);
    Group = __decorate([
        Component
    ], Group);
    return Group;
}(Vue));
export default Group;
