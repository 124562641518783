var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable import/extensions */
import { Component, Vue, Watch, } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { firebaseApp } from '../db';
import electionService from '../services/election';
import blockchainService from '../services/blockchain';
import { GroupDB } from '../services/group';
import authService from '../services/auth';
import { normalizeAddress } from '../validation';
import UserAPI from '../services/userapi';
var alert_store = namespace('Alert');
var NewElection = /** @class */ (function (_super) {
    __extends(NewElection, _super);
    function NewElection() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.displayQrScanner = false;
        _this.description = '';
        _this.newParticipant = '';
        _this.newVoteOption = '';
        _this.voteOptions = ['Accept', 'Reject'];
        _this.beginHeight = -1;
        _this.endHeight = -1;
        _this.sliderMinHeight = 0;
        _this.sliderMaxHeight = 1;
        _this.currentHeight = -1;
        _this.participants = [];
        _this.ownedGroups = [];
        _this.authObserver = null;
        _this.groupDB = new GroupDB();
        _this.allowedTags = null;
        _this.selectedTags = [];
        _this.electionTypeOptions = [
            { text: 'Transparent', value: 'multioptionvote' },
            { text: 'Anonymous', value: 'ringsignature' },
        ];
        _this.visibilityOptions = [
            { text: 'Participants only', value: 'participants' },
            { text: 'Everyone', value: 'public' },
        ];
        _this.electionType = _this.electionTypeOptions[0].value;
        _this.visibility = _this.visibilityOptions[0].value;
        _this.createElectionEnabled = false;
        return _this;
    }
    NewElection.prototype.created = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.authObserver = authService.observeAuth()
                            .subscribe(function (currentUser) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        console.log('NewElection event: ', currentUser === null || currentUser === void 0 ? void 0 : currentUser.uid, currentUser === null || currentUser === void 0 ? void 0 : currentUser.isAnonymous);
                                        if (!currentUser || currentUser.isAnonymous) {
                                            return [2 /*return*/];
                                        }
                                        return [4 /*yield*/, this.onUserLoaded(currentUser.uid)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); }, function (error) {
                            console.warn("subscribe error ".concat(error));
                        });
                        return [4 /*yield*/, this.getCurrentBlockHeight()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    NewElection.prototype.onUserLoaded = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            var groups, tags, _a, e_1, _b, e_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        groups = this.groupDB.getOwnerOf(user);
                        tags = UserAPI.listAllowedTags();
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        _a = this;
                        return [4 /*yield*/, groups];
                    case 2:
                        _a.ownedGroups = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _c.sent();
                        console.warn(e_1);
                        this.displayAlert("Failed to load groups: ".concat(e_1));
                        return [3 /*break*/, 4];
                    case 4:
                        _c.trys.push([4, 6, , 7]);
                        _b = this;
                        return [4 /*yield*/, tags];
                    case 5:
                        _b.allowedTags = (_c.sent()).tags;
                        return [3 /*break*/, 7];
                    case 6:
                        e_2 = _c.sent();
                        console.warn(e_2);
                        this.displayAlert("Failed to load tags: ".concat(e_2));
                        this.allowedTags = [];
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    NewElection.prototype.getCurrentBlockHeight = function () {
        return __awaiter(this, void 0, void 0, function () {
            var h;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, blockchainService.get_tip_height()];
                    case 1:
                        h = _a.sent();
                        this.currentHeight = h;
                        this.sliderMinHeight = h + 1; // Next block
                        this.sliderMaxHeight = h + blockchainService.days_to_blocks(30 * 6); // ~6 months
                        if (this.endHeight === -1) {
                            this.endHeight = h + blockchainService.days_to_blocks(30);
                        }
                        if (this.beginHeight === -1) {
                            this.beginHeight = h;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    NewElection.prototype.createElection = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var currentUserId, isAnonymous, electionId, e_3;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this.voteOptions.length < 2) {
                            this.displayAlert('You need at least 2 vote options');
                        }
                        if (this.description === '') {
                            this.displayAlert('Description is empty');
                            return [2 /*return*/];
                        }
                        if (this.participants.length === 0) {
                            this.displayAlert('Election has zero participants');
                            return [2 /*return*/];
                        }
                        if (this.endHeight === -1 || this.beginHeight === -1) {
                            this.displayAlert('Still loading blockchain height');
                            return [2 /*return*/];
                        }
                        currentUserId = (_a = firebaseApp().auth().currentUser) === null || _a === void 0 ? void 0 : _a.uid;
                        isAnonymous = (_b = firebaseApp().auth().currentUser) === null || _b === void 0 ? void 0 : _b.isAnonymous;
                        this.createElectionEnabled = false;
                        if (!currentUserId || isAnonymous) {
                            this.displayAlert('You are not logged in');
                            this.$router.push({ name: 'login' });
                            return [2 /*return*/];
                        }
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, electionService.createElection(this.beginHeight, this.electionType, this.description, this.endHeight, this.voteOptions, this.participants, this.visibility, this.selectedTags)];
                    case 2:
                        electionId = _c.sent();
                        this.hideAlert();
                        this.$router.push({ name: 'election', params: { id: electionId } });
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _c.sent();
                        this.displayAlert("Election was not created: ".concat(e_3));
                        return [3 /*break*/, 4];
                    case 4:
                        this.createElectionEnabled = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    NewElection.prototype.addMultipleParticipants = function (participants) {
        var _this = this;
        participants.forEach(function (p) {
            _this.addOneParticipant(p);
        });
    };
    NewElection.prototype.addOneParticipant = function (participant) {
        if (!participant) {
            // Ignore empty input
            this.newParticipant = '';
            return;
        }
        var address = '';
        try {
            address = normalizeAddress(participant);
        }
        catch (e) {
            this.displayAlert(e);
            return;
        }
        var alreadyAdded = this.participants.includes(address);
        if (alreadyAdded) {
            // Ignore
            this.newParticipant = '';
            return;
        }
        this.participants.push(address);
        this.newParticipant = '';
    };
    NewElection.prototype.addParticipant = function () {
        var participantAddress = this.newParticipant.trim();
        this.addOneParticipant(participantAddress);
    };
    NewElection.prototype.removeEntryIfExists = function (list, entry) {
        var index = list.indexOf(entry);
        if (index === -1) {
            return;
        }
        list.splice(index, 1);
    };
    NewElection.prototype.removeParticipant = function (participantAddress) {
        this.removeEntryIfExists(this.participants, participantAddress);
    };
    NewElection.prototype.addVoteOption = function () {
        var option = this.newVoteOption.trim();
        if (this.voteOptions.indexOf(option) !== -1) {
            // Already added, ignore.
            this.newVoteOption = '';
            return;
        }
        if (option === '') {
            return;
        }
        this.newVoteOption = '';
        this.voteOptions.push(option);
    };
    NewElection.prototype.removeVoteOption = function (option) {
        this.removeEntryIfExists(this.voteOptions, option);
    };
    NewElection.prototype.onBeginHeightChange = function (newBeginHeight) {
        this.createElectionEnabled = true;
        this.endHeight = Math.max(this.endHeight, newBeginHeight + 1);
    };
    __decorate([
        alert_store.Action
    ], NewElection.prototype, "displayAlert", void 0);
    __decorate([
        alert_store.Action
    ], NewElection.prototype, "hideAlert", void 0);
    __decorate([
        Watch('beginHeight')
    ], NewElection.prototype, "onBeginHeightChange", null);
    NewElection = __decorate([
        Component
    ], NewElection);
    return NewElection;
}(Vue));
export default NewElection;
