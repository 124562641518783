import Vue from 'vue';
import Vuex from 'vuex';
import Alert from './alert';
import Main from './main';
Vue.use(Vuex);
var appModule = {
    modules: {
        Main: Main,
        Alert: Alert,
    },
};
var Store = new Vuex.Store(appModule);
export default Store;
