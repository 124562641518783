var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import firebase from 'firebase/app';
import bchaddr from 'bchaddrjs';
import { firestore } from '../db';
function toIGroup(doc) {
    var data = doc === null || doc === void 0 ? void 0 : doc.data();
    if ((doc === null || doc === void 0 ? void 0 : doc.data) === undefined) {
        throw Error('Group data missing');
    }
    return {
        groupId: doc.id,
        participants: data.participants,
        owner: data.owner,
        title: data.title,
    };
}
var GROUP_COLLECTION = 'group';
/**
 * Database access to group functionality
 */
var GroupDB = /** @class */ (function () {
    function GroupDB() {
    }
    GroupDB.prototype.create = function (owner, title, participants) {
        return __awaiter(this, void 0, Promise, function () {
            var group;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, firestore().collection(GROUP_COLLECTION)
                            .add({
                            owner: owner,
                            participants: participants,
                            title: title,
                        })
                            .catch(function (error) {
                            throw Error(error.message);
                        })];
                    case 1:
                        group = _a.sent();
                        return [2 /*return*/, group.id];
                }
            });
        });
    };
    GroupDB.prototype.get = function (groupId) {
        return __awaiter(this, void 0, Promise, function () {
            var snap;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, firestore().collection(GROUP_COLLECTION)
                            .doc(groupId)
                            .get()
                            .catch(function (error) {
                            throw Error(error);
                        })];
                    case 1:
                        snap = _a.sent();
                        if (!snap) {
                            throw Error('Group not found');
                        }
                        return [2 /*return*/, toIGroup(snap)];
                }
            });
        });
    };
    GroupDB.prototype.delete = function (groupId) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, firestore().collection(GROUP_COLLECTION)
                        .doc(groupId)
                        .delete()
                        .catch(function (error) {
                        throw Error(error);
                    })];
            });
        });
    };
    /**
     * Add a participant to an existing group
     */
    GroupDB.prototype.addParticipant = function (groupId, user) {
        return __awaiter(this, void 0, Promise, function () {
            var groupRef;
            return __generator(this, function (_a) {
                console.log("Add participant ".concat(user, " to ").concat(groupId));
                if (!bchaddr.isValidAddress(user)) {
                    throw Error('Not a valid Bitcoin Cash address');
                }
                groupRef = firestore().collection(GROUP_COLLECTION).doc(groupId);
                return [2 /*return*/, groupRef.update({
                        participants: firebase.firestore.FieldValue.arrayUnion(user),
                    })];
            });
        });
    };
    /**
     * Remove a participant from a group.
     */
    GroupDB.prototype.removeParticipant = function (groupId, user) {
        return __awaiter(this, void 0, Promise, function () {
            var groupRef;
            return __generator(this, function (_a) {
                groupRef = firestore().collection(GROUP_COLLECTION).doc(groupId);
                return [2 /*return*/, groupRef.update({
                        participants: firebase.firestore.FieldValue.arrayRemove(user),
                    })];
            });
        });
    };
    /**
     * Get groups that the user is participant of.
     */
    GroupDB.prototype.getParticipantOf = function (user) {
        return __awaiter(this, void 0, Promise, function () {
            var snap, groups;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, firestore().collection(GROUP_COLLECTION)
                            .where('participants', 'array-contains', user)
                            .get()
                            .catch(function (error) {
                            throw Error(error.message);
                        })];
                    case 1:
                        snap = _a.sent();
                        if (!snap) {
                            throw Error('Failed to retrieve participant groups');
                        }
                        groups = [];
                        snap.forEach(function (doc) {
                            groups.push(toIGroup(doc));
                        });
                        return [2 /*return*/, groups];
                }
            });
        });
    };
    /**
     * Get groups that the user is owner of.
     */
    GroupDB.prototype.getOwnerOf = function (owner) {
        return __awaiter(this, void 0, Promise, function () {
            var snap, groups;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, firestore().collection(GROUP_COLLECTION)
                            .where('owner', '==', owner)
                            .get()
                            .catch(function (error) {
                            throw Error(error.message);
                        })];
                    case 1:
                        snap = _a.sent();
                        if (!snap) {
                            throw Error('Failed to retrieve owned groups');
                        }
                        groups = [];
                        snap.forEach(function (doc) {
                            groups.push(toIGroup(doc));
                        });
                        return [2 /*return*/, groups];
                }
            });
        });
    };
    return GroupDB;
}());
export { GroupDB };
