import bchaddr from 'bchaddrjs';
/**
 * Clean address input. Convert it to lower-case, prefixed cashaddr.
 */
export function normalizeAddress(rawaddress) {
    var address = rawaddress.trim();
    if (bchaddr.isValidAddress(address) && bchaddr.isLegacyAddress(address)) {
        address = bchaddr.toCashAddress(address);
    }
    address = address.toLowerCase();
    if (address.startsWith('bchidentity://p2p?op=share&addr=')) {
        address = address.replace('bchidentity://p2p?op=share&addr=', '');
    }
    if (!address.startsWith('bitcoincash:')) {
        address = "bitcoincash:".concat(address);
    }
    if (!bchaddr.isValidAddress(address)) {
        throw Error('Invalid Bitcoin Cash address');
    }
    return address;
}
export function isDummy() { }
