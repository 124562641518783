var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable class-methods-use-this */
import { Observable } from 'rxjs';
import { firestore, firebaseApp } from '../db';
var AuthService = /** @class */ (function () {
    function AuthService() {
        this.db = firestore();
        this.allAuthEmitters = [];
    }
    AuthService.prototype.listenForAuthToken = function (userId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.db.collection('login')
                .doc(userId)
                .onSnapshot(function (doc) {
                if (doc) {
                    var data = doc.data();
                    if (typeof data !== 'undefined') {
                        if (typeof data.authtoken !== 'undefined') {
                            resolve(data.authtoken);
                        }
                    }
                }
            }, function (error) {
                reject(error.message);
            });
        });
    };
    AuthService.prototype.signinWithToken = function (token) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        firebaseApp().auth().signInWithCustomToken(token)
                            .then(function (user) {
                            resolve(user);
                        })
                            .catch(function (error) {
                            reject(error);
                        });
                    })];
            });
        });
    };
    AuthService.prototype.signinWithStoredToken = function () {
        return __awaiter(this, void 0, Promise, function () {
            var token, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        token = localStorage.getItem('auth-token');
                        if (token === null) {
                            return [2 /*return*/, false];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.signinWithToken(token)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 3:
                        e_1 = _a.sent();
                        console.warn("Failed to signin with stored token: ".concat(e_1));
                        localStorage.removeItem('auth-token');
                        return [2 /*return*/, false];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.requestChallenge = function () {
        return new Promise(function (resolve, reject) {
            firebaseApp().functions(process.env.VUE_APP_FIREBASE_REGION)
                .httpsCallable('request_challenge')()
                .then(function (res) {
                var challenge = res.data;
                resolve(challenge);
            })
                .catch(function (error) {
                reject(error);
            });
        });
    };
    AuthService.prototype.signInAnonymously = function () {
        return new Promise(function (resolve, reject) {
            firebaseApp().auth().signInAnonymously()
                .then(function (credential) {
                if (credential.user) {
                    resolve(credential.user);
                }
                else {
                    reject(Error('User missing in credentials'));
                }
            })
                .catch(function (error) {
                var errorMessage = error.message;
                reject(errorMessage);
            });
        });
    };
    AuthService.prototype.isAuthenticatedAnonymously = function () {
        var auth = firebaseApp().auth();
        return new Promise(function (resolve) {
            var _a, _b;
            if (auth && auth.currentUser !== undefined && ((_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.isAnonymous) !== undefined) {
                var isSignedInAnonymously = (_b = auth.currentUser) === null || _b === void 0 ? void 0 : _b.isAnonymous;
                resolve(isSignedInAnonymously);
            }
            else {
                resolve(false);
            }
        });
    };
    AuthService.prototype.isAuthenticated = function () {
        var _a, _b;
        var currentUser = ((_a = firebaseApp().auth().currentUser) === null || _a === void 0 ? void 0 : _a.uid) != null;
        var isSignedInAnonymously = (_b = firebaseApp().auth().currentUser) === null || _b === void 0 ? void 0 : _b.isAnonymous;
        return currentUser && !isSignedInAnonymously;
    };
    AuthService.prototype.getUserOrCrash = function () {
        var currentUser = firebaseApp().auth().currentUser;
        if (typeof currentUser !== 'undefined' && currentUser !== null) {
            return currentUser;
        }
        throw Error('Cannot get user');
    };
    AuthService.prototype.getCurrentUser = function () {
        var currentUser = firebaseApp().auth().currentUser;
        return currentUser;
    };
    AuthService.prototype.forceAuthEmit = function (emitters) {
        var currentUser = this.getCurrentUser();
        if (emitters) {
            emitters.forEach(function (e) { return e.next(currentUser); });
        }
        else {
            this.allAuthEmitters.forEach(function (e) { return e.next(currentUser); });
        }
    };
    AuthService.prototype.observeAuth = function () {
        var _this = this;
        return Observable.create(function (emitter) {
            _this.allAuthEmitters.push(emitter);
            _this.forceAuthEmit([emitter]);
            firebaseApp().auth().onAuthStateChanged(function (user) {
                emitter.next(user);
            }, function (error) {
                console.error("Auth observer error: ".concat(error));
            }, function () {
                console.log('Auth observer removed');
            });
        });
    };
    AuthService.prototype.signOut = function () {
        localStorage.removeItem('auth-token');
        return firebaseApp().auth().signOut();
    };
    AuthService.prototype.authTokenExists = function () {
        return localStorage.getItem('auth-token') !== null;
    };
    /**
     * Get headers for making a API request. If user is authenticated,
     * this will include an authentication token for accessing API calls
     * that require authentication.
     */
    AuthService.prototype.getRequestHeaders = function () {
        return __awaiter(this, void 0, void 0, function () {
            var headers, authToken, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        headers = {
                            Accept: 'application/json',
                            'Content-Type': 'application/json;charset=UTF-8',
                            cache: 'no-cache',
                            mode: 'cors',
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.getUserOrCrash().getIdToken(true)];
                    case 2:
                        authToken = _a.sent();
                        headers.authorization = "Bearer ".concat(authToken);
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        console.log("Making un-authenticated request, auth not available: ".concat(e_2));
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, headers];
                }
            });
        });
    };
    return AuthService;
}());
var authService = new AuthService();
export default authService;
