var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import util from 'util';
import { function_url } from '../db';
import 'firebase/firestore';
import authService from './auth';
var electionUtil = {
    toIElection: function (electionData, electionId) {
        return {
            content: electionData,
            id: electionId,
        };
    },
};
// TODO: Move to typescript class?
var electionService = {
    createElection: function (begin_height, contract_type, description, end_height, options, participants, visibility, tags) {
        return __awaiter(this, void 0, Promise, function () {
            var url, url_options, res, _a, _b, jsonRes;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        url = function_url('create_election');
                        _c = {
                            method: 'POST'
                        };
                        return [4 /*yield*/, authService.getRequestHeaders()];
                    case 1:
                        url_options = (_c.headers = _d.sent(),
                            _c.body = JSON.stringify({
                                begin_height: begin_height === -1 ? null : begin_height,
                                contract_type: contract_type,
                                description: description,
                                end_height: end_height,
                                options: options,
                                participants: participants,
                                visibility: visibility,
                                tags: tags,
                            }),
                            _c);
                        return [4 /*yield*/, fetch(url, url_options)];
                    case 2:
                        res = _d.sent();
                        if (!!res.ok) return [3 /*break*/, 4];
                        _a = Error;
                        _b = "Failed to create election: ".concat;
                        return [4 /*yield*/, res.text()];
                    case 3: throw _a.apply(void 0, [_b.apply("Failed to create election: ", [_d.sent()])]);
                    case 4: return [4 /*yield*/, res.json()];
                    case 5:
                        jsonRes = _d.sent();
                        return [2 /*return*/, jsonRes.election_id];
                }
            });
        });
    },
    getElection: function (electionId) {
        return __awaiter(this, void 0, Promise, function () {
            var url, url_options, res, _a, _b;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        url = function_url("get_election_details?election_id=".concat(electionId));
                        _c = {
                            method: 'GET'
                        };
                        return [4 /*yield*/, authService.getRequestHeaders()];
                    case 1:
                        url_options = (_c.headers = _d.sent(),
                            _c);
                        return [4 /*yield*/, fetch(url, url_options)];
                    case 2:
                        res = _d.sent();
                        if (!!res.ok) return [3 /*break*/, 4];
                        _a = Error;
                        _b = "Failed to request election details: ".concat;
                        return [4 /*yield*/, res.text()];
                    case 3: throw _a.apply(void 0, [_b.apply("Failed to request election details: ", [_d.sent()])]);
                    case 4: return [2 /*return*/, res.json()];
                }
            });
        });
    },
    getParticipantElections: function (current_user_id) {
        return __awaiter(this, void 0, Promise, function () {
            var url, url_options, res, _a, _b;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (authService.getUserOrCrash().isAnonymous) {
                            return [2 /*return*/, []];
                        }
                        url = function_url('list_participating_elections');
                        _c = {
                            method: 'GET'
                        };
                        return [4 /*yield*/, authService.getRequestHeaders()];
                    case 1:
                        url_options = (_c.headers = _d.sent(),
                            _c);
                        return [4 /*yield*/, fetch(url, url_options)];
                    case 2:
                        res = _d.sent();
                        if (!!res.ok) return [3 /*break*/, 4];
                        _a = Error;
                        _b = "Failed to fetch election user is participating in: ".concat;
                        return [4 /*yield*/, res.text()];
                    case 3: throw _a.apply(void 0, [_b.apply("Failed to fetch election user is participating in: ", [_d.sent()])]);
                    case 4: return [4 /*yield*/, res.json()];
                    case 5: return [2 /*return*/, (_d.sent()).elections.map(function (e) { return electionUtil.toIElection(e, e.id); })];
                }
            });
        });
    },
    getAdminElections: function (current_user_id) {
        return __awaiter(this, void 0, Promise, function () {
            var url, url_options, res, _a, _b;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (authService.getUserOrCrash().isAnonymous) {
                            return [2 /*return*/, []];
                        }
                        url = function_url('list_hosted_elections');
                        _c = {
                            method: 'GET'
                        };
                        return [4 /*yield*/, authService.getRequestHeaders()];
                    case 1:
                        url_options = (_c.headers = _d.sent(),
                            _c);
                        return [4 /*yield*/, fetch(url, url_options)];
                    case 2:
                        res = _d.sent();
                        if (!!res.ok) return [3 /*break*/, 4];
                        _a = Error;
                        _b = "Failed to fetch election user hosts: ".concat;
                        return [4 /*yield*/, res.text()];
                    case 3: throw _a.apply(void 0, [_b.apply("Failed to fetch election user hosts: ", [_d.sent()])]);
                    case 4: return [4 /*yield*/, res.json()];
                    case 5: return [2 /*return*/, (_d.sent()).elections.map(function (e) { return electionUtil.toIElection(e, e.id); })];
                }
            });
        });
    },
    getTally: function (election_id) {
        return __awaiter(this, void 0, Promise, function () {
            var url, options, response, json;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        url = util.format('%s?election_id=%s', function_url('get_tally'), election_id);
                        _a = {
                            method: 'GET'
                        };
                        return [4 /*yield*/, authService.getRequestHeaders()];
                    case 1:
                        options = (_a.headers = _b.sent(),
                            _a);
                        return [4 /*yield*/, fetch(url, options)];
                    case 2:
                        response = _b.sent();
                        return [4 /*yield*/, response.json()];
                    case 3:
                        json = _b.sent();
                        if (!response.ok) {
                            throw Error(json.message);
                        }
                        return [2 /*return*/, json];
                }
            });
        });
    },
};
export default electionService;
