var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Component, Vue, } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import authService from '../services/auth';
import electionService from '../services/election';
import blockchain from '../services/blockchain';
var alertStore = namespace('Alert');
var Election = /** @class */ (function (_super) {
    __extends(Election, _super);
    function Election() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.election = {};
        _this.ballots = null;
        _this.loadComplete = [false, false];
        _this.loadElectionError = false;
        _this.loadTallyError = false;
        _this.authObserver = null;
        _this.tallyTable = [];
        _this.allVotes = [];
        /**
         * Height of the Bitcoin Cash block chain
         */
        _this.currentHeight = -1;
        _this.LOAD_LEVEL_NO_AUTH = 0;
        _this.LOAD_LEVEL_AUTHENTICATED = 1;
        return _this;
    }
    Election.prototype.loadElection = function (electionID, loadLevel) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, e_1, _b, e_2, _c, table_1, e_3;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (this.loadComplete[loadLevel]) {
                            return [2 /*return*/];
                        }
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, , 4]);
                        _a = this;
                        return [4 /*yield*/, electionService.getElection(electionID)];
                    case 2:
                        _a.election = _d.sent();
                        this.loadElectionError = false;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _d.sent();
                        this.loadElectionError = true;
                        this.displayAlert("Failed to load election: ".concat(e_1));
                        return [3 /*break*/, 4];
                    case 4:
                        _d.trys.push([4, 6, , 7]);
                        _b = this;
                        return [4 /*yield*/, blockchain.get_tip_height()];
                    case 5:
                        _b.currentHeight = _d.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        e_2 = _d.sent();
                        this.displayAlert("Unable to fetch current block height: ".concat(e_2));
                        return [3 /*break*/, 7];
                    case 7:
                        _d.trys.push([7, 9, , 10]);
                        _c = this;
                        return [4 /*yield*/, electionService.getTally(electionID)];
                    case 8:
                        _c.ballots = _d.sent();
                        table_1 = {};
                        this.election.options.forEach(function (o) {
                            table_1[o] = 0;
                        });
                        if (this.election.contractType === 'twooptionvote') {
                            table_1.Blank = 0;
                        }
                        this.ballots.forEach(function (b) {
                            table_1[b.vote_option] += 1;
                        });
                        this.tallyTable = [table_1];
                        this.loadTallyError = false;
                        return [3 /*break*/, 10];
                    case 9:
                        e_3 = _d.sent();
                        this.loadTallyError = true;
                        this.displayAlert("Unable to tally the results: ".concat(e_3));
                        return [3 /*break*/, 10];
                    case 10:
                        this.loadComplete[loadLevel] = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    Election.prototype.created = function () {
        return __awaiter(this, void 0, void 0, function () {
            var electionID;
            var _this = this;
            return __generator(this, function (_a) {
                electionID = this.$route.params.id;
                this.authObserver = authService.observeAuth()
                    .subscribe(function (currentUser) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(!currentUser || currentUser.isAnonymous)) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.loadElection(electionID, this.LOAD_LEVEL_NO_AUTH)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                            case 2:
                                // Hide errors that happened before we got authenticated.
                                this.hideAlert();
                                return [4 /*yield*/, this.loadElection(electionID, this.LOAD_LEVEL_AUTHENTICATED)];
                            case 3:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); }, function (error) {
                    console.warn("subscribe error ".concat(error));
                });
                return [2 /*return*/];
            });
        });
    };
    __decorate([
        alertStore.Action
    ], Election.prototype, "displayAlert", void 0);
    __decorate([
        alertStore.Action
    ], Election.prototype, "hideAlert", void 0);
    Election = __decorate([
        Component
    ], Election);
    return Election;
}(Vue));
export default Election;
